.filterByorder {
  display: flex;
  width: 22.5rem;
  /* width: 100%; */
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 13px 31px 3px rgba(169, 169, 169, 0.37);
  z-index: 22;
  position: relative;
}

.filterByOrderMobile {
  display: flex;
  width: 22.5rem;
  /* width: 100%; */
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header h1 {
  color: var(--light-black);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}

.order_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.order_type_name {
  display: flex;
  padding: 0.4rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 47px;
  border: 1px solid var(--blue);
  background: var(--white);
  color: var(--blue);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
}

.order_type_name_selected {
  background: var(--blue);
  color: var(--white);
}

.line {
  stroke: var(--grey-80);
  opacity: 0.5;
  width: 100%;
  height: 0.2rem;
  margin: 0;
}

.text {
  color: var(--grey);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8258;
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  width: 100%;
}

@media (max-width: 550px) {
  .filterByOrderMobile {
    gap: 16px;
  }
  .header h1 {
    font-size: 14px;
  }

  .order_type_name {
    padding: 0.4rem 0.8rem;
    font-size: 14px;
  }
}
