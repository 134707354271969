.button {
  /* padding: 9px 18px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  box-sizing: border-box;
  display:flex ;
  align-items: center;
  justify-content: center; */
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  box-sizing: border-box;
  height: fit-content;
  display:flex ;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

@media(max-width:400px){
  .button{
    width: 100%;
    font-size: 14px;
  }
}