.logout_Container {
  width: 20%;
  min-width: 20rem;
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 30px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
}
.logout_popup {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.logout_heading {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.4px;
  color: var(--light-black);
}

.logout_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--grey);
}
.footer_logout_btn {
  display: flex;
  gap: 16px;
}
