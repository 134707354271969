.overview_page {
  width: 100%;
  height: 100%;
  padding: 30px 30px;
}

.overview_section {
  display: flex;
  padding: 24px 30px;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  gap: 40px;
  border-radius: 16px;
  border: 0.5px solid var(--jordy-blue);
  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(29, 22, 23, 0.05);
  width: 50%;
}

.overview_section2 {
  display: flex;
  padding: 24px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  border-radius: 16px;
  border: 0.5px solid var(--jordy-blue);
  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(29, 22, 23, 0.05);
  width: 100%;
  margin-top: 2rem;
}

.overview_bottom_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.overview_section1 {
  /* width: 500px; */
  padding-bottom: 0;
  gap: 0px;
}

.overview_top_section {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.title {
  display: flex;
  align-items: center;
  color: var(--light-black);
  /* font-family: "SF Pro Display"; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  gap: 5px;
}

.progressbar_container {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mixed_chart_container {
  height: 100%;
  width: 60%;
}

.outer_title {
  display: none;
}

@media (max-width: 980px) {
  .overview_section {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .overview_section2 {
    border: transparent;
    box-shadow: 0px 0px 0px 0px rgba(29, 22, 23, 0.05);
  }

  .overview_top_section {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 2rem;
  }

  .overview_bottom_section {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    position: relative;
  }
  .title {
    display: none;
  }

  .outer_title {
    /* display: block; */
    display: flex;
    margin-top: 2rem;
    margin-bottom: -0.5rem;
    text-align: left;
  }

  .average_order_value_heading {
    margin-top: 4rem;
    margin-bottom: -2rem;
  }

  .progressbar_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
    padding: 24px 30px;
    gap: 40px;
    border-radius: 16px;
    border: 0.5px solid var(--jordy-blue);
    background: var(--white);
    box-shadow: 0px 0px 10px 0px rgba(29, 22, 23, 0.05);
    margin-bottom: 9rem;
  }

  .mixed_chart_container {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .overview_section,
  .progressbar_container {
    border: 0.5px solid var(--grey-80);
  }
}

@media (max-width: 550px) {
  .progressbar_container {
    height: 15rem;
  }

  .overview_page {
    padding: 20px 21px;
  }

  .overview_section {
    padding: 20px 16px;
  }

  .overview_section2 {
    border: transparent;
    box-shadow: 0px 0px 0px 0px rgba(29, 22, 23, 0.05);
    padding: 0px;
  }
}
