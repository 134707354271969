.settings_page {
  top: clamp(9rem, 22vh, 10rem);
  display: flex;
  position: relative;
}

.allPages {
  width: 100%;
}

.sidebar {
  display: block;
}

.searchbar {
  height: 4.5rem;
}

@media (max-width: 768px) {
  .sidebar,
  .searchbar {
    display: none;
  }

  .settings_page {
    top: 0;
    display: flex;
    position: relative;
  }
}
