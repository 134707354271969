.loader_parent {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 99999999999999999;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 50vw; */
  padding: 4% 6%;
  gap: 16px;
  border-radius: 24px;
  background: var(--white);
}

.loaderImg {
  height: 25%;
  width: 25%;
  text-align: center;
  animation: loader-spin 3s linear infinite;
}

.heading {
  font-size: 36px;
  font-weight: 700;
  line-height: 42.96px;
  text-align: center;
  color: var(--primary);
}

.subHeading {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--dark-grey);
}

@media (max-width: 768px) {
  .loader {
    width: 70vw;
    gap: 6px;
    padding: 6%;
  }
  .loader img {
    text-align: center;
  }

  .heading {
    font-size: 24px;
    font-weight: 700;
    margin-top: 1rem;
  }

  .subHeading {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .loader {
    gap: 0px;
    width: 90vw;
  }
  .loader img {
    height: 20%;
    width: 20%;
    text-align: center;
  }
  .heading {
    font-size: 20px;
    font-weight: 700;
    margin-top: 0rem;
  }
  .subHeading {
    font-size: 12px;
    line-height: 16px;
  }
}

@keyframes loader-spin {
  0% {
    transform: scale(1) rotate(0deg);
  }

  25% {
    transform: scale(1.2) rotate(90deg);
  }

  50% {
    transform: scale(1) rotate(180deg);
  }

  75% {
    transform: scale(1.2) rotate(270deg);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}
