.ProfilePopup_Container {
    width: 15%;
    min-width: 17rem;
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px 0px;
    border-radius: 12px;
    background-color: var(--white);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
}

.ProfilePopup {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.ProfilePopup_data {
    display: flex;
    gap: 12px;
    padding: 13px 20px;
}
.ProfilePopup_data:hover{
    background-color: var(--light-grey);
    cursor: pointer;
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    color: var(--smoky-black);
}

.logout_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    color: var(--red);
}