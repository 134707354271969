.message_page {
  margin-top: clamp(5rem, 1vh, 10rem);
}

.message_list {
  overflow-y: scroll;
  height: 100vh;
  width: fit-content;
}

.message_list::-webkit-scrollbar {
  width: 10px;
}

.message_list::-webkit-scrollbar-thumb {
  background-color: var(--grey-80);
  border-radius: 16px;
  opacity: 1;
}

.message_list::-webkit-scrollbar-track {
  background-color: var(--white);
}

.connect_left_chat_box {
  background-color: var(--light-grey);
  padding: 20px;
  width: 25%;
  min-width: 20rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--light-grey);
}

.left_chat_box {
  background-color: var(--white);
  padding: 20px;
  width: 25%;
  min-width: 20rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--light-grey);
  position: relative;
}

.profile_img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

.message_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.unread_count {
  display: flex;
  justify-content: flex-end;
}

.unread_message {
  background-color: var(--green);
  color: white;
  font-size: 0.8em;
  border-radius: 50%;
  height: 21px;
  width: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: var(--smoky-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 21.48px;
}

.label {
  color: var(--smoky-black);
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
}

.outer_dot {
  /* display: flex; */
  /* justify-content: center;
    align-items: center; */
  width: 10px;
  height: 10px;
  background-color: var(--white);
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  left: 60px;
  top: 55px;
  bottom: 0px;
}

.status_dot {
  min-width: 8px;
  min-height: 8px;
  background-color: green;
  border-radius: 50%;
  margin: -1px 0px 0px -1px;
}

.time {
  color: var(--grey);
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
}

.mobileNavbar {
  display: none;
  /* justify-content: space-between; */
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background: var(--blue);
  height: 65px;
  padding: 10px 16px;
  gap: 8px;
  z-index: 99;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
  color: var(--white);
}

.searchBar_parent {
  width: 100%;
  max-width: 332px;
  position: relative;
}

.page_info_Icon {
  position: absolute;
  top: 9px;
  right: 2px;
  padding: 0px 13px;
  border-left: 0.5px solid var(--grey-80);
}


@media (max-width: 768px) {
  .mobileNavbar {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.48px;
    display: flex;
  }

  .message_page {
    margin-top: 0vh;
  }

  .message_list {
    width: 100%;
    margin-bottom: 5rem;
  }

  .connect_left_chat_box {
    width: 100%;
  }

  .left_chat_box {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .page_info_Icon {
    right: 30px;
  }
}

@media (max-width: 330px) {
  .connect_left_chat_box {
    min-width: 18rem;
  }

  .left_chat_box {
    min-width: 18rem;
  }
}
