.file_input {
  display: none;
}

.value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  gap: 8px;
  /* padding: 0px 24px 0px 0px; */
  /* padding-left: 0px; */
  /* position: relative; */
}

.submit_file_upload_label {
  position: relative;
  display: flex;
  min-width: 14rem;
  width: 100%;
  /* margin-left: 3rem; */
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  color: var(--blue);
  background: rgba(22, 93, 201, 0.1);
  cursor: pointer;
}

.file_upload_label .icon {
  display: flex;
  padding: 0px;
}

.file_upload_label {
  display: flex;
  width: 100%;
  min-width: 14rem;
  height: 50px;
  /* margin-left: 1rem; */
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  color: var(--blue);
  background: rgba(22, 93, 201, 0.1);
  cursor: pointer;
}

.cancel_img {
  position: absolute;
  right: -5px;
  top: -6px;
  padding: 0px;
  border-radius: 100%;
  border: transparent;
  background-color: transparent;
}

.cancel_img:hover {
  background-color: transparent;
}
.pdf_values {
  display: flex;
  flex-direction: column;
}
.pdf_values span {
  padding: 0px;
}

.first_value {
  color: var(--smoky-black);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.last_value {
  color: var(--grey);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.received_doc_url_image {
  width: 100%;
  height: 100%;
  max-height: 9rem;
}

.received_doc_url {
  width: 100%;
  height: 100%;
  max-height: 9rem;
}

@media (max-width: 992px) {
  .file_upload_label {
    height: 45px;
    min-width: 10.5rem;
    margin-right: -1rem;
    /* min-width: 12rem;
    margin-left: 0.5rem; */
    padding: 10px 8px;
    gap: 6px;
  }
}

@media (max-width: 700px) {
  .file_upload_label {
    min-width: 12rem;
  }
}

@media (max-width: 550px) {
  .file_upload_label {
    min-width: 10rem;
  }
}

@media (max-width: 370px) {
  .file_upload_label {
    min-width: 8rem;
    /* margin-left: -1rem; */
  }
}
