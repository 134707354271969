.emptyStateComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  top: 1.5rem;
  height: 100%;
  width: 100%;
}

.emptyStateComponent img {
  height: "100%";
  width: "100%";
}

.emptyStateHeading {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.64px;
  margin-top: 1rem;
  text-align: center;
  color: var(--primary);
}

.emptyStateSubHeading {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  margin-top: 0.5rem;
  text-align: center;
  max-width: 33%;
}

@media (max-width: 545px) {
  .emptyStateComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    top: -5.5rem;
    height: 100%;
    width: 100%;
  }

  .emptyStateSubHeading {
    font-size: 14px;
    max-width: 66%;
  }
}
