.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  padding: 8px 1rem 5px 1rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  border-top: 0.5px solid var(--light-grey);
  box-shadow: 0px 0px 10px 0px #1d161712;
}

.display {
  display: none;
}

.option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--light-black);
  font-size: 12px;
  /* padding: 12px 20px 16px 20px; */
  gap: 5px;
}

.optionIcon {
  border-radius: 16px;
  position: relative;
  /* transform: scale(1.5); */
  padding: 6px 20px;
}

.selectedOptionIcon {
  padding: 6px 20px;
  background: #b7d4ff;
  border-radius: 16px;
  opacity: 0.5px;
  position: relative;
  /* transform: scale(1.5); */
}

@media (max-width: 768px) {
  .display {
    display: flex;
  }
}
