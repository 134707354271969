.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding:2rem;
  gap: 20px;
}

.btn {
  border: 1px solid var(--grey-80);
  background-color: var(--white);
  padding: 10px 6px;
}

.btn:hover {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
}

.btn1 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transform: rotate(90deg);
}

.btn2 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transform: rotate(-90deg);
}

@media (max-width: 786px) {
  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem;
    padding-right: 1rem;
    position: relative;
    gap: 18px;
    margin-bottom: 2.5rem;
    margin-top: -7rem;
  }

  .pagination p {
    font-size: 14px;
    margin-right: 1rem;
  }

  .btn {
    border: 1px solid var(--grey-80);
    background-color: var(--white);
    padding: 7px 4px;
  }

  .btn:hover {
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
  }

  .btn1 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transform: rotate(90deg);
  }

  .btn2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transform: rotate(-90deg);
  }
}
