.parent{
    margin-top: 65px;
    display: none;
    flex-direction: column;
}

@media(max-width:768px){
    .parent{
        display: flex;
    }
}