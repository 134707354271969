.DropdownList {
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
}

.DropdownList_data {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 13px 20px;
}

.text {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    color: var(--smoky-black);
}

.thisYear_radiobtn{
    width: 20px;
    height: 20px;
}