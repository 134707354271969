.emptybox {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 50vh;
  font-size: 2rem;
}

.edit_save_button_section {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  position: fixed;
  top: clamp(5.5rem, 7vh, 7rem);
  right: 30px;
  z-index: 10;
}

.Profile_Container {
  width: auto;
  padding: 24px 40px 60px 15px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  margin-bottom: 2rem;
}

.Profile_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.text {
  color: var(--smoky-black);
  font-size: 20px;
  font-weight: 600;
  line-height: 23.87px;
}

.mandatory_data {
  color: var(--red);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
}

.Main_Container {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  border-radius: 8px;
  border: 0.5px solid var(--grey-80);
  position: relative;
}

.profile_personal_details {
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
}

.cameraIcon {
  cursor: pointer;
}

.camera_outer_dot {
  width: 29px;
  height: 29px;
  background-color: var(--blue);
  border-radius: 50%;
  padding: 4px;
  position: absolute;
  left: 51px;
  top: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile_img {
  border-radius: 50%;
  background-color: var(--jordy-blue);
  width: 80px;
  height: 80px;
}

.Profile_data {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name {
  color: var(--smoky-black);
  font-size: 28px;
  font-weight: 600;
  line-height: 33.41px;
}

.label {
  color: var(--grey);
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
}

.heading {
  color: var(--regal-blue);
  font-size: 20px;
  font-weight: 500;
  line-height: 23.87px;
}

.main_container_form {
  display: flex;
  gap: 36px;
  width: 100%;
}

.form_data_one {
  justify-content: space-between;
}

.form_data {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.form_sub_data {
  position: relative;
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
}

.form_data_Bio {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;
  position: relative;
}

.form_label {
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  position: relative;
}

.received_doc_url {
  position: absolute;
  right: 5%;
}

.permanent_label {
  margin-bottom: 47px;
}

.checkbox_container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.checkbox_container input {
  width: 18px;
  height: 18px;
}

.checkbox_data {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: var(--grey);
}

.notification_checkbox_data {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: var(--grey);
}

.star_mark {
  color: var(--red);
}

.error {
  position: absolute;
  bottom: -25px;
  color: var(--red);
  font-size: 14px;
  margin: -5px 0px 0px 10px;
}

/* .form_data input {
    border-radius: 8px;
    padding: 12px 20px;
    background-color: var(--light-grey);
    border: none;
    outline: none;
    height: 2rem;
} */

/* .form_data input::placeholder {
    color: var(--smoky-black);
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
} */

textarea {
  resize: none;
  outline: none;
  border: none;
  height: 15rem;
  padding: 12px 20px;
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  border-radius: 8px;
  background: var(--light-grey);
}

.file_input {
  display: none;
}

.submit_file_upload_label {
  position: relative;
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--light-grey);
  cursor: pointer;
}

.cancel_img {
  position: absolute;
  right: -5px;
  top: -4.917px;
  pointer-events: none;
}

.pdf_values span {
  padding: 0px;
  display: flex;
}

.first_value {
  color: var(--smoky-black);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.last_value {
  color: var(--grey);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.footer_data {
  display: flex;
  justify-content: flex-end;
}

.mobileView_footerBtn {
  display: flex;
  background: var(--white);
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  gap: 10px;
  padding: 16px 16px 16px 16px;
  box-shadow: 0px -4px 20px 0px #00000026;
}

.hideOnDesktop {
  display: none;
}

.mobileNavbar {
  display: none;
  /* justify-content: space-between; */
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background: var(--blue);
  height: 65px;
  padding: 10px 16px;
  gap: 8px;
  z-index: 99;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
  color: var(--white);
}

.settings_page {
  top: clamp(9rem, 22vh, 10rem);
  display: flex;
  position: relative;
}

.allPages {
  width: 100%;
}

.sidebar {
  display: block;
}

/*---------------- Professional Details ----------------- */

.table_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.service_list_table {
  margin: 0rem 2rem;
  height: fit-content;
  width: 100%;
}

.data_row td {
  padding: 0.2rem 0rem;
}

.data_row td:last-child {
  width: 10%;
  min-width: 4rem;
}

.data_row td:last-child span {
  margin-right: 0.5rem;
}

.professional_text {
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
}

.professional_textlink {
  color: var(--blue);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
}

.dropfile_container {
  display: flex;
  gap: 30px;
  padding: 30px;
  border-radius: 4px;
  border: 1px dotted var(--blue);
  background-color: var(--light-blue);
}

.dropfile_container_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.dropfile_Icon {
  display: flex;
  align-items: center;
}

.browseData {
  color: var(--blue);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
  margin-left: 4px;
}

.add_other_input {
  position: relative;
}

.cancel_add_other {
  position: absolute;
  top: 12px;
  right: 13px;
  transform: scale(1.2);
  cursor: pointer;
}

.edit_service_inputs {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.browseData label {
  cursor: pointer;
}

.dropfileValues {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  color: var(--grey);
}

.add_button_container {
  width: 100%;
  position: relative;
  margin-top: 2rem;
}

/*---------------- Financial Details ----------------- */

.financial_upi_data {
  display: flex;
  gap: 6px;
  color: var(--blue);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  cursor: pointer;
  margin-top: 15px;
}

.cancelledCheque_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

/*---------------- Security Settings ----------------- */

.Security_Main_Container {
  width: auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--grey-80);
}

.security_form_data {
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
  border-bottom: 0.5px solid rgba(204, 204, 204, 0.5);
}

.security_form_data:last-child {
  border-bottom: none;
}

.professional_form_data {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.security_Icondata {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
}

.SecuritySettings_footer {
  display: flex;
  justify-content: flex-start;
}

.security_heading_data {
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

.callNumber {
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
}

.location {
  display: flex;
  align-items: center;
  gap: 8px;
}

.textData {
  display: flex;
  justify-content: center;
  gap: 6px;
  flex-direction: column;
}

.error_message {
  color: rgba(255, 0, 0, 0.765);
}

/*---------------- Billing History ----------------- */

.billing_data {
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
}

.BillingHistory_footer {
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
}

/*---------------- Notification Settings ----------------- */

.notification_Main_Container {
  width: auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 30px;
  gap: 24px;
  border: 1px solid var(--grey-80);
}

.notification_form_data {
  display: flex;
  justify-content: space-between;
}

.notification_time_container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.notification_time {
  display: flex;
  min-width: 8rem;
  position: relative;
  align-items: center;
  gap: 10px;
  border: 0.5px solid var(--grey-80);
  border-radius: 5px;
  padding: 10px 12px;
  background-color: var(--light-grey);
  /* font-size: 16px; */
}

.time,
.time_option {
  border: none;
  outline: none;
  background-color: var(--light-grey);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.6px;
}

.dropdown_container {
  position: absolute;
  right: 0px;
  top: 5px;
}

/* ----------Media Queries ----------- */

@media (max-width: 768px) {
  .Profile_Container {
    padding: 24px 15px 60px 15px;
  }
  .mobileNavbar {
    font-size: 17px;
    font-weight: 500;
    line-height: 21.48px;
    display: flex;
  }
  .edit_save_button_section {
    display: none;
  }

  .hideOnDesktop {
    display: flex;
    align-items: center;
  }

  .hideOnMobile {
    display: none;
  }

  .pick_services_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .pick_services_container .form_data {
    width: 100%;
  }

  .service_list_table {
    margin: 0rem 0rem;
    /* min-width: 20rem; */
  }

  .main_container_form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

/* @media (max-width: 600px) {
  .form_data {
    width: 48%;
  }
} */

@media (max-width: 550px) {
  .Profile_Container {
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .permanent_label {
    margin-bottom: 10px;
  }

  .Main_Container {
    gap: 24px;
    padding: 16px;
    padding-bottom: 27px;
  }

  .profile_img {
    width: 70px;
    height: 70px;
  }

  .camera_outer_dot {
    left: 48px;
    top: 43px;
  }

  .form_data {
    width: 100%;
  }

  .form_label {
    color: var(--smoky-black);
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    margin-top: 0.5rem;
  }

  .form_sub_data {
    display: flex;
    gap: 12px;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
  }

  textarea {
    font-size: 14px;
    height: 10rem;
  }

  .heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
  }

  .Profile_heading_onMobile {
    color: var(--smoky-black);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    margin-bottom: -0.5rem;
  }

  .checkbox_container input {
    width: 16px;
    height: 16px;
  }

  .checkbox_data {
    font-size: 12px;
    display: contents;
  }

  .professional_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
  }

  .professional_textlink {
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
  }

  .dropfile_container {
    display: flex;
    gap: 12px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
  }

  .dropfile_container_data {
    align-items: center;
  }

  .dropfileValues {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
  }

  .browseData {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .financial_upi_data {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
  }

  .security_heading_data {
    font-size: 14px;
    line-height: 19.6px;
  }

  .callNumber {
    font-size: 14px;
    line-height: 19.6px;
  }

  .security_Icondata {
    font-weight: 500;
  }

  .notification_Main_Container {
    padding: 16px 16px 18px 16px;
  }

  .notification_form_data_mobile {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .notification_time {
    min-width: 7.6rem;
  }

  /* .Security_Main_Container {
    border: none;
  } */
  .security_form_data {
    padding: 16px;
  }

  .location {
    gap: 4px;
  }

  .backButton {
    position: relative;
    top: -2px;
  }

  .service_list_table td {
    font-size: 14px;
    margin: 5px 2px;
  }

  .service_list_table th {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .sidebar,
  .searchbar {
    display: none;
  }

  .settings_page {
    top: 0;
    display: flex;
    position: relative;
  }
}
