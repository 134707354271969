.filterByAmount {
  display: flex;
  width: 22.5rem;
  /* width: 100%; */
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 13px 31px 3px rgba(169, 169, 169, 0.37);
  z-index: 22;
  position: relative;
}

.filterByAmountMobile {
  display: flex;
  width: 22.5rem;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header h1 {
  color: var(--light-black);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}
label {
  display: flex;
  align-items: center;
  color: var(--grey);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.input_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

label {
  position: relative;
}

.rupee1 {
  position: absolute;
  top: 15px;
  left: 50px;
}

.rupee2 {
  position: absolute;
  top: 15px;
  left: 32px;
}

.input {
  margin-left: 10px;
  width: 7rem;
  height: 3rem;
  padding: 8px 18px 8px 20px;
  gap: 12px;
  border-radius: 8px;
  border: 0.5px solid var(--grey-80);
  background: var(--light-grey);
  color: var(--smoky-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
}

.slider {
  width: 100%;
  /* height: 2px; */
  /* display: flex;
    align-items: center; */
}

.line {
  stroke: var(--grey-80);
  opacity: 0.5;
  width: 100%;
  height: 0.2rem;
  margin: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  width: 100%;
}

@media (max-width: 550px) {
  .filterByAmountMobile {
    gap: 16px;
  }

  .header h1 {
    font-size: 14px;
  }

  .slider {
    margin-top: 24px;
  }
}
