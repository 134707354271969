.container {
  display: flex;
  padding: 20px;
  height: 100%;
  margin-bottom: 4rem;
}

.left_container {
  width: 50%;
  /* height: fit-content; */
  height: auto;
  background: var(--white);
  padding-bottom: 1rem;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 0.5px solid rgba(137, 176, 235, 0.5);
}

.left_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px;
}

.left_header h2 {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 22.4px;
}

.left_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left_img {
  padding: 20px 0px;
}

.right_container {
  width: 50%;
  height: fit-content;
  /* padding-bottom: 1rem; */
  background: var(--white);
  margin-bottom: 10px;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 0.5px solid rgba(137, 176, 235, 0.5);
}

.right_header {
  padding: 20px;
  background-color: var(--light-blue);
}

.right_header h2 {
  text-align: left;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 22.4px;
}

.right_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top: 0.5px solid rgba(137, 176, 235, 0.5);
}

.right_box_name {
  text-align: justify;
  font-weight: 700;
  font-size: 18px;
  line-height: 22.4px;
}

.right_box_data {
  width: 30%;
  min-width: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.right_box_data p {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
}

.positive {
  color: var(--green);
  margin-left: 8px;
  font-size: 14px;
  font-weight: bolder;
}

.negative {
  color: var(--red);
  margin-left: 8px;
  font-size: 14px;
  font-weight: bolder;
}

.left_header_onMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left_header_onMobile h2 {
  margin-bottom: 1.3rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 22.4px;
}

.right_header_onMobile h2 {
  text-align: left;
  margin-bottom: 1.3rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 22.4px;
}

.left_header_onMobile,
.right_header_onMobile {
  display: none;
}

.emptybox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  font-size: 2rem;
}

@media (max-width: 1024px) {
  .right_box_name {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .left_container {
    margin-bottom: 2rem;
    margin-right: 0px;
    padding: 24px;
    width: 100%;
    border: 0.5px solid var(--grey-80);
  }

  .right_container {
    width: 100%;
    border: 0.5px solid var(--grey-80);
  }

  .right_box {
    border-top: 0px;
    border-bottom: 0.5px solid var(--grey-80);
  }

  .right_box:last-child {
    border-top: 0px;
    border-bottom: 0px;
  }

  .left_header_onMobile,
  .right_header_onMobile {
    display: flex;
    padding: 0px;
  }

  .left_header,
  .right_header {
    display: none;
  }
}

@media (max-width: 400px) {
  .left_header_onMobile h2,
  .right_header_onMobile h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.09px;
    color: var(--smoky-black);
  }

  .right_box {
    padding: 20px 10px;
  }

  .right_box_data {
    min-width: 9rem;
  }

  .right_box_name {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
  }

  .right_box_data p {
    font-size: 14px;
  }
}
