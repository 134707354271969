.heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    color: var(--navy-blue);
}

.main_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.left_rating_container {
    width: auto;
}

.right_rating_container {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.left_rating_card {
    display: flex;
    border-radius: 20px;
    padding: 48px 35px;
    gap: 10px;
    box-shadow: 0px 10px 40px 0px #1D16170F;
}

.left_rating_card_data {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.left_rating_heading {
    font-size: 40px;
    font-weight: 600;
    line-height: 47.73px;
    color: var(--navy-blue);
    margin: 0px;
}

.rating_data {
    display: flex;
    gap: 12px;
}

.star_icon {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    color: var(--smoky-black);
}

.line {
    border-bottom: 1px solid var(--grey-80);
}

.parent_bar_container {
    display: flex;
    gap: 11px;
    flex-direction: column;
}

.parent_bar {
    display: flex;
    justify-content: space-between;
}

.parent_bar_label {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
    color: var(--smoky-black);
}

.parent_bar_value {
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    color: var(--smoky-black);
}

.progress_bar {
    width: 100%;
    border-radius: 20px;
    height: 7px;
    background-color: #EBEBEB;
}
.inner_bar{
    border-radius: 20px;
    height: 7px;
}

.more_review_data {
    margin: 1rem 0rem;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    color: var(--smoky-black);
}