.menu_option {
  position: fixed;
  right: 2%;
  top: 5.7rem;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.menu_option_active {
  background-color: var(--light-grey);
}

.notificationList {
  margin-top: clamp(9rem, 22vh, 10rem);
}

.colon_popup {
  width: 10%;
  min-width: fit-content;
  height: auto;
  border-radius: 8px;
  padding: 8px 10px;
  background-color: var(--white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  position: absolute;
  right: 70px;
  top: 120px;
}

.backButton {
  position: relative;
  top: -1px;
}

@media (max-width: 768px) {
  .menu_option {
    display: none;
  }

  .notificationList {
    margin-top: 135px;
    margin-bottom: 100px;
  }
}
