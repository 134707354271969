.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 2vw;
  padding-left: 0px;
  min-width: 790px;
  background-color: var(--white);
  position: relative;
  z-index: 1;
}

.left_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  min-width: 44rem;
}

.left_container_data {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  border-radius: 8px;
  padding: 10px;
  background-color: var(--white);
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--light-black);
  border: 0.5px solid var(--grey-80);
}

.left_container_data_search1 {
  width: 100%;
}

.left_container_data_one {
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  gap: 5px;
  padding: 10px;
  width: fit-content;
}

.left_container_data:last-child {
  color: var(--red);
}

.left_container_data_first {
  border-radius: 8px 0px 0px 8px;
  padding: 4px 0px 3px 0px;
  position: relative;
  width: 26%;
  cursor: pointer;
  gap: 10px;
  background-color: var(--white);
  font-weight: 400;
  font-size: 16px;
  line-height: 21.4px;
  color: var(--light-black);
  border: 0.5px solid var(--grey-80);
}

.left_container_data_middle {
  border-radius: 0px;
  border-left: none;
}

.left_container_data_last {
  border-radius: 0px 8px 8px 0px;
  border-left: none;
}

.icon {
  /* transform: rotate(180deg); */
  transition: all 200ms ease;
}

.icon_rotate {
  transform: rotate(180deg);
  transition: all 200ms ease;
}

.resetIcon {
  transform: rotate(-360deg);
  transition: all 500ms ease;
}

.calendar {
  padding: 11px 6px 8px 6px;
  height: 0rem;
  position: relative;
}

.calendarInput {
  padding: 0px;
  height: 28px;
  width: 177px;
  border: transparent;
  font-size: 14px;
  outline: transparent;
  position: relative;
  text-align: center;
  top: -8px;
}

.calendarInput:focus {
  outline: none;
  border: transparent;
}

.pickerStyle {
  width: fit-content;
  height: fit-content;
  position: absolute !important;
  top: 52px !important;
  left: -76px !important;
}

@media (max-width: 1127px) {
  /* .container {
    padding: 12px 30px;
    flex-wrap: wrap;
  } */

  .right_container {
    margin-left: 6.5rem;
    margin-top: 0.5rem;
  }
}

@media (max-width: 1024px) {
  .container {
    flex-wrap: wrap;
  }

  .search_bar_container {
    margin-left: 7.3rem;
    margin-top: 0.3rem;
  }
}

@media (max-width: 768px) {
  .container {
    display: none;
  }

  .left_container {
    display: none;
  }
}
