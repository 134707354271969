.heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    color: var(--navy-blue);
}

.more_review_container{
    display: flex;
    /* margin-top: 1rem; */
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}