/* .helpSupport_container {
  height: 100%;
} */

.Support_Container {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--light-blue);
  border-bottom: 0.5px solid var(--grey-80);
  padding: 45px 40px;
  margin-top: clamp(4.5rem, 1vh, 10rem);
}

.Support_Container > p:first-child {
  font-size: 20px;
  color: var(--smoky-black);
  font-weight: 600;
  line-height: 23.87px;
  text-align: left;
}

.Support_Container > p:nth-child(2) {
  width: 70%;
  font-size: 16px;
  color: var(--grey);
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
}

.footer_buttons {
  display: flex;
  gap: 30px;
  align-items: center;
}

.call {
  min-width: 10.5rem;
}

.mail {
  min-width: 14.5rem;
}

.footer_buttons .Support_call,
.footer_buttons .Support_mail {
  font-size: 16px;
  color: var(--smoky-black);
  font-weight: 500;
  line-height: 22.4px;
  margin-left: 0.5rem;
  border-bottom: 1px solid var(--smoky-black);
}

.ticket {
  width: fit-content;
  padding: 45px 40px;
}

.ticket_container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: var(--white);
  /* padding: 0px 40px; */
  gap: 24px;
  flex-wrap: wrap;
}

.ticket_header {
  width: 100%;
  /* padding: 45px 40px 10px 40px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 30px 0px;
}

.ticket_header h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 23.87px;
  color: var(--smoky-black);
}

.link,
.article_link {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  color: var(--royal-blue);
  text-decoration: none;
}

.link:hover {
  color: var(--royal-blue);
  text-decoration: underline;
}

.create_ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--light-blue);
  border: 1px solid var(--grey-80);
  border-radius: 8px;
  padding: 12px 16px;
  min-width: 10rem;
  height: 146px;
  text-align: center;
  cursor: pointer;
}

.create_ticket p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
}

.ticket_card {
  display: flex;
  gap: 12px;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--grey-80);
  border-radius: 8px;
  padding: 24px;
  min-width: 15rem;
}

.ticket_card h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  margin: 0px;
}

.ticket_card p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: var(--grey);
}

.ticket_card p span {
  color: var(--royal-blue);
}

.time {
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

.ticket_card_data {
  display: flex;
  justify-content: space-between;
}

.ticket_card_data .status_active {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  color: var(--dull-orange);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
}

.ticket_card_data .status_close {
  display: flex;
  gap: 4px;
  align-items: flex-end;
  color: var(--grey);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
}

.card_Heading {
  padding: 30px 40px 0px 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.87px;
  text-align: left;
}

.Articles_Data {
  padding: 0px 0px 40px 45px;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  gap: 24px;
  margin-bottom: 3rem;
}

.card_data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 11rem;
}

.card {
  width: 17rem;
  height: 23rem;
  padding: 16px;
  border: 0.5px solid var(--grey-80);
  border-radius: 8px;
}

.card_img {
  width: 100%;
  border-radius: 6px;
}

.card_title {
  color: var(--smoky-black);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.card_para {
  color: var(--grey);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
}

.card_btn {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--royal-blue);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
  text-decoration: none;
  margin-bottom: -0.5rem;
  cursor: pointer;
}

.hideOnDesktop {
  display: none;
}

.mobileNavbar {
  display: none;
  /* justify-content: space-between; */
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background: var(--blue);
  height: 65px;
  padding: 10px 16px;
  gap: 8px;
  z-index: 99;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
  color: var(--white);
}

@media (max-width: 768px) {
  .mobileNavbar {
    font-size: 17px;
    font-weight: 500;
    line-height: 21.48px;
    display: flex;
  }

  .Support_Container {
    width: auto;
    gap: 16px;
    padding: 20px 16px;
    margin-top: 0vh;
  }

  .Articles_Data {
    padding: 0px 0px 40px 45px;
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    gap: 24px;
    margin-bottom: 3rem;
  }

  .footer_buttons {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
}

@media (max-width: 550px) {
  .hideOnDesktop {
    display: flex;
    align-items: center;
  }

  .hideOnMobile {
    display: none;
  }

  .Support_Container > p:first-child {
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
  }

  .Support_Container > p:nth-child(2) {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
  }

  .footer_buttons .Support_call,
  .footer_buttons .Support_mail {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
  }

  .ticket {
    padding: 16px;
  }

  .ticket_header {
    padding: 16px 0px 10px;
  }

  .card_Heading {
    padding: 0px 16px;
  }

  .ticket_header h2 {
    font-size: 16px;
    line-height: 22.4px;
  }

  .create_ticket {
    width: 100%;
    height: 120px;
  }

  .link,
  .create_ticket p {
    font-size: 14px;
  }

  .article_link {
    font-size: 14px;
    padding-right: 16px;
  }

  .Articles_Data {
    padding: 0px 16px 16px 16px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .ticket_card {
    width: 100%;
    padding: 16px;
  }

  .ticket_card h2 {
    font-size: 16px;
  }

  .ticket_card p {
    font-size: 14px;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 17rem;
    padding: 10px;
    gap: 16px;
  }

  .card_title {
    font-size: 14px;
  }

  .card_para {
    font-size: 12px;
    line-height: 16.8px;
  }

  .card_btn {
    font-size: 14px;
  }

  .mobileView_footer_btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 0px 16px;
    margin-bottom: 6rem;
  }

  .mobileView_footer_data {
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: var(--smoky-black);
  }

  .backButton {
    position: relative;
    top: -1px;
  }
}
