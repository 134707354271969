.notification_enable {
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 13px;
  padding: 4px 2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  background-color: var(--blue);
  transition: all 200ms ease;
}

.notification_disabled {
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 13px;
  padding: 4px 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: var(--light-grey);
  transition: all 200ms ease;
}

.notification_icon {
  /* position: absolute;
  top: 6px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}
