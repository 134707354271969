.popup_message_bar {
  width: 100%;
  display: flex;
  padding: 20px 30px;
  align-items: center;
  gap: 10px;
  border-bottom: 0.5px solid var(--grey-80);
  background: var(--titan-white);
}

.popup_message_bar_label {
  color: var(--grey);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup_message_bar_value {
  display: flex;
  padding: 2px 6px;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: var(--white);
  color: var(--smoky-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer_btn {
  display: flex;
  align-items: center;
}

.message_popup_icon {
  display: flex;
  gap: 10px;
}

.file_input {
  display: none;
}

.file_upload_label .icon {
  display: flex;
  cursor: pointer;
}

.compose_popup_buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
}

@media (max-width:550px){
  
}

@media(max-width:400px) {
  .footer_btn {
    display: block;
  }
  .compose_popup_buttons {
    display: flex;
    justify-content: space-between;
  }
  .message_popup_icon {
    margin-bottom: 1.5rem;
  }
}