.addService_page {
  margin: 1rem 2rem;
  margin-top: clamp(10rem, 10vh, 16rem);
}

.add_section_sub_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 0.5px solid var(--grey-80);
  background: var(--floral-white);
  display: flex;
  width: 100%;
  min-height: 70px;
  padding: 10px 40px 20px 40px;
  position: fixed;
  top: clamp(4.5rem, 5vh, 8rem);
  right: 0;
  left: 5%;
  z-index: 9;
}

.section_heading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 24, 61, 1);
  /* margin: 0rem 0rem 2rem 2rem; */
}

.section_heading span {
  cursor: pointer;
  margin-top: -4px;
}

.textarea {
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  height: 15rem;
  padding: 12px 20px;
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  border-radius: 8px;
  background: var(--light-grey);
}

.amount_section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}

.bottom_button_section {
  display: flex;
  justify-content: space-between;
  /* margin: 1rem 2rem; */
}

@media (max-width: 768px) {
  .add_section_sub_header {
    display: none;
  }
  .addService_page {
    margin: 1rem 2rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
