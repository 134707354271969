.sortByYearContainer {
  padding: 0px 30px;
}

.sortByYearMenu {
  display: flex;
  padding: 8px 25px 8px 35px;
  align-items: center;
  justify-content: flex-end;
  /* gap: 10px; */
  border-radius: 8px;
  border: 0.5px solid var(--jordy-blue);
  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(29, 22, 23, 0.05);
  color: var(--light-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  appearance: none;
  line-height: 22.4px;
  cursor: pointer;
  outline: none;
}

.calendarIcon {
  position: absolute;
  top: 7px;
  left: 5px;
}

.cursorIcon {
  position: absolute;
  top: 9px;
  right: 10px;
  pointer-events: none;
}
.cursorIconDown {
  position: absolute;
  top: 9px;
  right: 10px;
  pointer-events: none;
  transform: rotate(180deg);
}

.dropdownMenu {
  display: flex;
  width: 130px;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 13px 61px 0px rgba(169, 169, 169, 0.37);
  position: absolute;
  z-index: 1;
  margin-top: 3px;
}

.option {
  display: flex;
  height: 48px;
  /* padding: 113px 20px; */
  padding-left: 20px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  background: var(--white);
}

.option:hover {
  background-color: var(--light-grey);
  cursor: pointer;
}

.yearList {
  display: flex;
  height: 40px;
  padding: 6px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 0.5px solid var(--jordy-blue);
  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(29, 22, 23, 0.05);
}

.year {
  color: var(--light-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
}

.selected {
  background: var(--blue);
  color: var(--white);
}

.sortByYearMenuParent {
  position: relative;
}
.dropdownMenuClosed {
  display: none;
}
.bottom_dropdownMenu {
  display: none;
}

@media (max-width: 768px) {
  .sortByYearContainer {
    padding: 16px;
  }
}

@media (max-width: 425px) {
  .dropdownMenu {
    display: none;
  }
  .bottom_dropdownMenu {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    display: block;
  }
}
