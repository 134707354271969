.edit_button {
  background-color: var(--yellow);
  padding: 10px 24px 10px 24px;
  gap: 8px;
  border-radius: 8px;
  font-size: 15px;
  z-index: 1000;
  border: transparent;
  cursor: pointer;
}

.edit_button:hover {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.6);
}

.discard_button {
  background-color: rgba(255, 0, 0, 0.6);
  padding: 10px 24px 10px 24px;
  gap: 8px;
  border-radius: 8px;
  font-size: 15px;
  z-index: 1000;
  border: transparent;
  cursor: pointer;
}

.discard_button:hover {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.6);
}
