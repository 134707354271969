.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: clamp(4.5rem, 5vh, 8rem);
  padding: 0px 20px 0px 40px;
  background: var(--floral-white);
  position: fixed;
  top: 0;
  right: 0;
  left: 5%;
  /* overflow: hidden; */
  z-index: 99;
}

.header h2 {
  color: var(--navy-blue);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header_right_section {
  /* width: 12%; */
  gap: 20px;
}

.profile_section {
  padding: 5px 10px;
  gap: 15px;
  border-radius: 50px;
  cursor: pointer;
}

.profile_section:hover {
  background-color: var(--light-grey);
}

.profile_section_active {
  background-color: var(--light-grey);
  padding: 5px 10px;
  border-radius: 50px;
  gap: 15px;
  cursor: pointer;
}

.profile_name {
  color: var(--smoky-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile_img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.notificationList {
  width: 384px;
  height: 406px;
  position: absolute;
  top: 32px;
  right: 20px;
  padding: 8px 0px 8px 0px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  box-shadow: 0px 13px 21px 0px #a9a9a95d;
  background: var(--white);
  z-index: 999999999999999999;
  overflow-y: scroll;
}

.notificationList::-webkit-scrollbar {
  width: 5px;
}

.notificationList::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 10px;
  background-color: var(--grey-80);
  opacity: 1;
}

.bell_icon {
  padding: 10px;
  cursor: pointer;
  border-radius: 100px;
}

.bell_icon:hover {
  background-color: var(--light-grey);
}

.bell_icon_active {
  background-color: var(--light-grey);
  padding: 10px;
  border-radius: 100px;
}

.profile_dropdown {
  position: absolute;
  top: 9vh;
  right: 10px;
}

.open_cursor {
  transform: rotate(180deg);
  transition: all 300ms ease;
}

@media (max-width: 768px) {
  .header {
    display: none;
  }
}
