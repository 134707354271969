.DropdownList_parent {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.dropdownList {
  border-radius: 8px;
  padding: 12px 20px;
  /* background-color: var(--light-grey); */
  border: none;
  outline: none;
  /* height: 2rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.dropdownList_text {
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
}

.DropdownList_Data {
  margin-top: 5px;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  max-height: 15rem;
  overflow-y: scroll;
  width: 100%;
  top: 105%;
  z-index: 2;
}

.DropdownList_Data::-webkit-scrollbar {
  width: 4px;
}

.DropdownList_Data::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0, 0.2);
  border-radius: 6px;
}

.DropdownList_Data::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.DropdownList_childData {
  padding: 6px 12px;
  display: flex;
  gap: 10px;
}

.checkbox {
  display: flex;
  margin-top: 3px;
  align-self: flex-start;
}

.DropdownList_childData:hover {
  background-color: var(--light-grey);
}

.DropdownList_Cursor {
  transform: rotate(180deg);
}

.emptyInput {
  position: relative;
  /* pointer-events: none; */
}

.emptyInput::after {
  content: "*required";
  color: var(--red);
  position: absolute;
  font-size: 14px;
  left: 7px;
  bottom: -22px;
}

@media (max-width: 550px) {
  .dropdownList_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
  }
  .DropdownList_childData {
    font-size: 14px;
  }
}
