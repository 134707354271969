/* .radioBtn {
}

.radioBtn::after {
  content: "";
  background-color: var(--blue);
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 2px solid var(--grey-80);
}

.radioBtnSelected::after {
  content: "";
  background-color: var(--grey-80);
  width: 20px;
  height: 20px;
  border: 2px solid var(--blue);
} */

input[type="radio"] {
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 10px;
  border: 2px solid var(--grey-60);
  /* transition: 0.2s all linear; */
  margin-right: 5px;
  position: relative;
  /* top: 7px; */
}

input[type="radio"]:checked {
  width: 20px;
  height: 20px;
  padding: 12px;
  border: transparent;
  background-color: var(--blue);
}

input[type="radio"]:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 14px;
  height: 14px;
  border-radius: 30px;
  background-color: var(--white);
}

/* 
input[type="radio"]:not(:disabled):checked {
  border-color: var(--red);
  background-color: var(--blue);
  background-clip: content-box;
  padding: 2px;
  background-image: radial-gradient(
    circle,
    var(--radio-checked-color) 0%,
    var(--radio-checked-color) 50%,
    transparent 60%,
    transparent 100%
  );
} */
