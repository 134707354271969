.settings_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 382px;
  padding: 72px 0px 30px 2vw;
  gap: 30px;
}

.option {
  color: var(--smoky-black);
  font-weight: bolder;
  max-width: 320px;
  width: 23vw;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 16px 20px 16px 20px;
  gap: 15px;
  border-radius: 8px;
  position: relative;
}

.option:hover {
  background-color: var(--light-grey);
}

.active_option {
  background: var(--light-grey);
  color: var(--blue);
}

.hide {
  display: none;
}

.sub_option_list {
  display: flex;
  flex-direction: column;
}

.sub_option {
  color: var(--smoky-black);
  width: 100%;
  min-width: 22vw;
  height: 56px;
  padding: 16px 20px 16px 4vw;
  gap: 6px;
}

.active_sub_option {
  color: var(--blue);
  text-decoration: none;
}

.cursor {
  /* position: absolute;
  right: 25%; */
}

.downCursor {
  /* position: absolute;
    right: 25%; */
  transform: rotate(180deg);
}

.profile_settings_text {
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;
}

.logout {
  color: var(--red);
}
