.save_changes_btn {
  padding: 10px 24px 10px 24px;
  gap: 8px;
  border-radius: 8px;
  font-size: 15px;
  z-index: 1000;
  border: transparent;
  /* width: 100%; */
}

.active {
  background-color: var(--yellow);
  cursor: pointer;
}

.unActive {
  background-color: #b8b8b8;
  color:#837f7f;
  cursor:not-allowed;
}

.save_changes_btn:hover {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.6);
}
