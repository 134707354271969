.main_Container {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px;
    border-bottom: 0.5px solid var(--grey-80);
}

.profile_setting_details {
    display: flex;
    align-items: center;
    gap: 12px;
}

.profile_img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.Profile_data {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.name {
    color: var(--smoky-black);
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
}

.label {
    color: var(--grey);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.profile_icon {
    display: flex;
    align-items: center;
}

.notification_setting {
    display: flex;
    gap: 16px;
}

.profile_heading {
    color: var(--smoky-black);
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
}

.profile_Logout {
    color: var(--red);
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
}

@media(max-width:550px) {
    .backButton {
        position: relative;
        top: -1px;
    }
}