.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--blue);
  color: var(--white);
  height: 100vh;
  padding: 1rem 0px;
  width: 5%;
  min-width: 56px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.navbar_section2,
.navbar_section3 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10vh;
}

.option {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vw;
  /* max-width: 5vw; */
  min-width: 56px;
}

.selectedOption {
  background: rgba(255, 255, 255, 0.1);
  position: relative;
}

.selectedOption::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 7%;
  background-color: var(--white);
  border-radius: 0 10px 10px 0;
}

@media (max-width:768px){
  .navbar{
    display: none;
  }
}



