/* ------------ expand information ------------------ */

.expanInfo {
  position: relative;
  cursor: pointer;
}

.informationContent {
  z-index: 99999;
  position: absolute;
  top: 30px;
  left: 0;
  width: 15vw;
  min-width: 5rem;
  max-width: 20rem;
  padding: 10px;
  text-align: left;
  border: 1px solid;
  border-radius: 10px;
  font-size: 12px;
  background-color: var(--floral-white);
}

@media (max-width: 768px) {
  .expanInfo {
    position: relative;
    cursor: pointer;
  }

  .informationContent {
    left: -7rem;
    width: 70vw;
  }
}

/*----------------- Breakout chart---------------------- */

.breakoutChart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.revenue_chart_section {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.revenueBreakout_doughnut_parent {
  width: 100%;
  height: 100%;
}

.revenue_breakout_legend1 {
  display: flex;
  flex-direction: column;
  /* width: 12rem; */
  position: absolute;
  left: 6vw;
  bottom: 25%;
}

.revenue_breakout_legend2 {
  display: flex;
  flex-direction: column;
  /* width: 12rem; */
  position: absolute;
  right: 0vw;
  bottom: 25%;
}

.legend_title1 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.legend_title2 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.total_revenue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.total_revenue_amount {
  color: var(--smoky-black);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.total_revenue_title {
  color: var(--dark-grey);
  /* font-family: "SF Pro Display"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  /* 140% */
}

@media (max-width: 1300px) {
  .revenue_breakout_legend1 {
    display: flex;
    flex-direction: column;
    /* width: 12rem; */
    position: absolute;
    left: 2vw;
    bottom: 2vw;
  }

  .revenue_breakout_legend2 {
    display: flex;
    flex-direction: column;
    /* width: 12rem; */
    position: absolute;
    right: -22px;
    bottom: 0;
  }

  .legend_title2 {
    display: flex;
    align-items: flex-start;
    width: 7rem;
    gap: 5px;
  }

  .comissions_dot {
    margin-top: 5px;
  }
}

/* -------------Order breakout chart------------------------ */

.orderBreakoutChart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 3rem;
}

.orderBreakout_doughnut_parent {
  width: 100%;
  min-width: 200px;
  position: relative;
}

.doughnut_chart canvas {
  height: 300px !important;
}

.breakout_doughtnut_chart {
  height: 166px !important;
}

.breakout_center_text {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.breakout_center_text h1 {
  color: var(--smoky-black);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.breakout_center_text p {
  color: var(--grey);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  /* 140% */
}

.legend_list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.breakout_legend {
  color: var(--grey);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
  /* 140% */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.breakout_legend b {
  color: var(--smoky-black);
}

.legend_color {
  /* min-width: 1.2rem;
  min-height: 0.7rem; */
  min-width: 0.7rem;
  min-height: 0.7rem;
  border-radius: 50%;
}

.space {
  width: 100%;
  border-top: 0.2px dotted var(--grey);
}

/* ----------------------.salesAnalysisChart_center_text-------------------- */

.salesAnalysisChart_center_text {
  position: absolute;
  inset: 0;
  top: -2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
}

.salesAnalysisChart_center_text h1 {
  color: var(--smoky-black);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.salesAnalysisChart_center_text p {
  color: var(--grey);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  /* 140% */
}

@media (max-width: 405px) {
  /* .salesAnalysisChart_center_text {
    top: -5.5rem;
  } */
  .salesAnalysisChart_center_text h1 {
    font-size: 24px;
    font-weight: 700;
  }

  .salesAnalysisChart_center_text p {
    font-size: 12px;
  }
}

@media (max-width: 318px) {
  .salesAnalysisChart_center_text {
    top: -4.5rem;
  }
}

/* ------------------orderbreakout charts------------------------------- */
@media (max-width: 550px) {
  .orderBreakoutChart {
    gap: 1rem;
  }

  .orderBreakout_doughnut_parent {
    /* width: 60%; */
    min-width: 130px;
    position: relative;
    /* height: 10rem; */
  }

  .doughnut_chart canvas {
    height: 160px !important;
  }

  .breakout_center_text h1 {
    font-size: 22px;
  }

  .breakout_center_text p {
    font-size: 12px;
    line-height: 5px;
  }

  .doughnut_chart {
    height: 10rem !important;
  }

  .breakout_legend {
    font-size: 12px;
  }

  /* -------------------------------------revenuebreakout chart-------------------------------------- */

  .revenueBreakout_doughnut_parent {
    width: 30%;
    height: 100%;
    min-width: 130px;
  }

  .revenue_breakout_legend1 {
    display: flex;
    flex-direction: column;
    /* width: 12rem; */
    position: absolute;
    left: 6vw;
    bottom: 25%;
    font-size: 14px;
  }

  .total_revenue_amount {
    color: var(--smoky-black);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .revenue_breakout_legend2 {
    text-align: left;
    display: flex;
    flex-direction: column;
    /* width: 12rem; */
    position: absolute;
    right: -6vw;
    bottom: 18%;
    font-size: 14px;
  }

  .legend_title1 {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 5px;
  }

  .legend_title2 {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 5px;
  }

  .legend_title2 p {
    text-align: left;
    width: fit-content;
  }

  .breakout_doughtnut_chart {
    height: 106px !important;
  }
}

@media (max-width: 380px) {
  .revenue_breakout_legend1 {
    left: 0vw;
  }

  .revenue_breakout_legend2 {
    right: -10vw;
  }
}
