.parent_page {
  position: relative;
  height: 100vh;
}

.table thead {
  background-color: rgba(22, 93, 201, 0.1);
}

.main_heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  color: var(--smoky-black);
}

.main_heading_data {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: var(--grey);
  margin-bottom: 10px;
}

.table_data {
  display: flex;
  align-items: center;
  gap: 6px;
}

.table_data span {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody {
  position: relative;
}

.table td {
  vertical-align: top;
  background-color: var(--white);
}

.table td,
.table th {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  padding: 16px 10px;
  border-bottom: 1px solid rgba(173, 136, 53, 0.4);
}

.table .job_details {
  padding-left: 2rem;
}

.multi_btn {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 12px;
  min-width: 6rem;
  width: 70%;
  cursor: pointer;
}

/* .table_data_first {
  border-radius: 8px;
  width: 100%;
}
.table_data_second {
  border-radius: 8px;
  width: 100%;
} */

.refresh_button {
  position: fixed;
  z-index: 99999;
  top: 5rem;
  right: 2rem;
}

.order_card_detail {
  display: flex;
  align-items: flex-start;
  /* width: 100%; */
  gap: 6px;
  margin-bottom: 0.5rem;
}

.order_card_detail span {
  width: 100%;
  /* text-align: left; */
}

.order_card_detail > span:first-child {
  /* min-width: 100%; */
  /* width: 40%; */
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  word-wrap: break-word;
  color: var(--grey);
}

.order_card_detail span:last-child {
  word-break: break-word;
  /* min-width: 50%;
  width: 60%; */
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--smoky-black);
}

.hideOnMobile,
.hideOnMobile1,
.Message_hideOnMobile {
  display: none;
}

/* Reason_for_Rejected CSS Start ------ */
.reason_for_reject {
  display: flex;
  align-items: flex-start;
  width: 200px;
  background-color: rgba(204, 204, 204, 0.2);
  border-radius: 8px;
  padding: 16px;
  gap: 10px;
}

.reason_for_reject span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
}

.reason_for_reject_onMobile {
  width: auto;
  background-color: rgba(204, 204, 204, 0.2);
  border-radius: 8px;
  padding: 12px;
  margin: 0rem 1rem 2rem;
}

.reason_for_reject_onMobile p {
  color: var(--red);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.reason_for_reject_onMobile span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
}

.order_card_detail span.attachments_value {
  text-decoration: underline;
  color: var(--royal-blue);
  cursor: pointer;
}

.clip {
  position: relative;
  transform: scale(1.2);
}

.attachment_count {
  position: absolute;
  right: 9px;
  bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--royal-blue);
  color: var(--white);
  font-size: 10px;
}

/* Reason_for_Rejected CSS End ------ */

@media (max-width: 992px) {
  .order_card_detail {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0rem 0.5rem;
    margin-top: 130px;
    margin-bottom: 90px;
  }

  .order_card_detail {
    flex-wrap: wrap;
  }

  .table thead {
    display: none;
  }

  .job_details {
    padding-left: 1rem;
  }

  .order_card_detail span:first-child {
    min-width: 50%;
    width: 100%;
  }

  .order_card_detail span:last-child {
    min-width: 50%;
    width: 100%;
  }
  .refresh_button {
    display: none;
  }
  .refresh_button_mobile {
    display: block;
    position: fixed;
    top: 20px;
    right: 60px;
    z-index: 9999;
  }
}

@media (max-width: 550px) {
  .parent_page {
    position: relative;
    height: 90vh;
  }

  .table .Message_hideOnMobile {
    display: block;
  }

  .table .hideOnMobile {
    display: block;
    padding: 1rem;
    padding-bottom: 0.6rem;
  }

  .table .hideOnMobile1 {
    display: block;
    /* margin-bottom: 2rem; */
  }

  .hideOnDesktop {
    display: none;
  }

  .order_card_detail {
    flex-wrap: nowrap;
    padding: 0.3rem 0rem;
  }

  .table thead {
    display: none;
  }

  .table td,
  .table th {
    display: contents;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    margin-bottom: 0.8rem;
  }

  .table tr {
    border-radius: 8px;
    box-shadow: 0px 1px 2px 2px rgba(29, 22, 23, 0.15);
  }

  .table tr,
  .table td {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .order_card_detail span:first-child {
    padding: 0rem 1rem;
    font-size: 14px;
  }

  .order_card_detail span:last-child {
    text-align: right;
    min-width: 0%;
    padding-right: 1rem;
    font-size: 14px;
  }

  .multi_btn {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-around;
    gap: 12px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: inherit;
    /* margin: 0rem 0.2rem 2rem; */
  }

  .table_data_first {
    width: 50%;
  }

  .table_data_second {
    width: 50%;
  }

  .table_data_first_Completed {
    width: 100%;
  }
}
