.dropfile_container {
  display: flex;
  gap: 30px;
  padding: 30px;
  border-radius: 4px;
  border: 1px dotted var(--blue);
  background-color: var(--light-blue);
}

.dropfile_container_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.dropfile_Icon {
  display: flex;
  align-items: center;
}

.browseData {
  color: var(--blue);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
  margin-left: 4px;
}

.browseData label {
  cursor: pointer;
}

.dropfileValues {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  color: var(--grey);
}

.file_input {
  display: none;
}

.hideOnDesktop {
  display: none;
}

.previewContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.image_preview_parent {
  position: relative;
  height: auto;
  width: auto;
}

.preview_cancel_icon {
  cursor: pointer;
  position: absolute;
  top: -11px;
  right: -7px;
}

.image_preview {
  width: 130.2px;
  height: 120px;
  /* padding: 40px 20px 40px 20px; */
  gap: 24px;
  border-radius: 4px;
  border: 1px dotted #808080;
}

.doc_preview {
  border-radius: 4px;
  border: 1px dotted #808080;
  position: relative;
}

.doc_preview_page_icon {
  width: 130.2px;
  height: 120px;
}

.details_container{
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items: center;
  position: absolute;
  text-align: center;
  inset: 0 ;
  height:120px ;
}

.doc_preview_type {
  position: absolute;
  text-align: center;
  padding: 3px 0px;
  width: 95px;
  top:50px;
  background-color: red;
  color: white;
}

.doc_preview_name {
  font-size: 14px;
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 130.2px;
}

@media (max-width: 550px) {
  .dropfile_container {
    display: flex;
    gap: 12px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
  }

  .dropfile_container {
    display: flex;
    gap: 12px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
  }

  .dropfile_container_data {
    align-items: center;
  }

  .dropfileValues {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
  }

  .browseData {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .hideOnMobile {
    display: none;
  }

  .hideOnDesktop {
    display: flex;
  }
}
