.serialNumber {
  display: flex;
  width: 26px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  color: var(--smoky-black);
  background-color: var(--yellow);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  margin-right: 0.7rem;
  padding-left: 2px;
}

.accordion {
  position: relative;
  width: 70%;
  margin: 10px;
  border: 1px solid var(--grey-80);
  border-radius: 8px;
  background-color: var(--white);
  margin: 0px 40px 45px 40px;
}

.accord_i {
  font-size: 0.9rem;
  padding: 0.3rem 0.58rem;
  margin-right: 10px;
  background-color: var(--yellow);
  border: none;
  border-radius: 50%;
}

.accordion_content {
  border-bottom: 1px solid var(--grey-80);
  padding: 0px 24px;
}

.accordion .accordian-title {
  margin-left: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.accordion .accordion_content .question {
  padding: 15px 25px 0px 0px;
  cursor: pointer;
  color: var(--smoky-black);
  font-size: 18px;
  font-weight: 600;
  display: flex;
  position: relative;
  width: auto;
}

.accordion_content .question::after {
  top: 6%;
  content: "›";
  font-weight: normal;
  font-size: 3rem;
  position: absolute;
  right: 10px;
  color: var(--blue);
  transform: rotate(90deg);
}

.accordion .accordion_content .answer {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 17px;
  line-height: 1.2;
  width: 100%;
  overflow: hidden;
  height: 0px;
  opacity: 0;
  transition: height 0.7s ease, opacity 0.15s ease-in-out;
}

.accordion .accordion_content.active .answer {
  height: fit-content;
  opacity: 1;
}

.accordion .accordion_content.active .question {
  font-size: 1rem;
  transition: 0.5s;
}

.questionContent {
  width: 90%;
  word-break: keep-all;
}

.accordion .accordion_content.active .question::after {
  position: absolute;
  top: -20%;
  content: " ‹";
  font-size: 3rem;
  transition: 0.5s;
  color: var(--blue);
  transform: rotate(90deg);
}

@media (min-width: 1500px) {
  .accordion .accordion_content .question {
    font-size: 1.4rem;
  }

  .accordion .accordion_content.active .question {
    font-size: 1.5rem;
  }

  .accordion .accordion_content .answer {
    font-size: 1.3rem;
  }

  .accordion .accordion_content.active .answer {
    font-size: 1.3rem;
  }
}

@media (max-width: 1024px) {
  .accordion_content {
    padding: 16px;
  }
}

@media (max-width: 768px) {
  .serialNumber {
    padding-left: 0px;
  }

  h2 {
    font-size: 26px;
  }

  .accordion {
    width: auto;
    margin: 0px 16px;
    /* padding: 20px; */
  }

  .accordion_content .question::after {
    top: -25%;
    font-size: 2rem;
    right: 0px;
  }

  .accordion .accordion_content.active .question::after {
    top: -25%;
    font-size: 2rem;
  }

  .accordion .accordion_content .question {
    padding: 0px;
    font-size: 18px;
  }

  .accordion .accordion_content .answer {
    font-size: 16px;
  }

  .accordion .accordion_content.active .answer {
    height: fit-content;
  }

  .accordion .accordion_content.active .question {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .accordion {
    /* margin-top: 100px; */
    position: relative;
    top: -20%;
    width: auto;
    /* padding: 30px; */
    border: 1px solid var(--grey-80);
    border-radius: 8px;
    background-color: var(--white);
    margin-bottom: 7rem;
  }

  .accordion .accordion_content.active .answer {
    height: auto;
  }

  .accordion_content .question::after {
    top: -25%;
    font-size: 2rem;
    right: 0px;
  }

  .accordion .accordion_content.active .question::after {
    top: -25%;
    font-size: 2rem;
  }

  .accordion .accordion_content .question {
    padding: 0px;
    font-size: 16px;
  }

  .accordion .accordion_content .answer {
    font-size: 14px;
  }

  .accordion .accordion_content.active .answer {
    height: fit-content;
  }

  .accordion .accordion_content.active .question {
    font-size: 16px;
  }
}

/* @starting-style {
  .answer {
    opacity: 0;
  }
} */
