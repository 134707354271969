body {
  margin: 0;
  font-family: "SF PRO Display", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p{
  margin: 0;
  text-align: left;
}

a {
  color: var(--smoky-black);
  text-decoration: none;
}

a:hover, a:focus{
  text-decoration: none;
}



/* ----------- removing cursor from input type number --------------- */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
