.dashboard {
  position: relative;
  display: flex;
  height: 100%;
  background-color: var(--white);
}

.right_section {
  width: 100%;
  margin-left: 5%;
}

.mobileNavbar {
  display: none;
  /* justify-content: space-between; */
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background: var(--blue);
  height: 65px;
  padding: 10px 16px;
  gap: 8px;
  z-index: 99;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
  color: var(--white);
}

.filter_icon_mobile {
  position: inherit;
  right: 10px;
  padding: 6px 10px;
}

.mobileNavbar_user {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
  color: var(--white);
}

/* common css for mobile dropdowns container */

.DropdownList_parent {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
}

.DropdownList_Container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  min-width: 18rem;
  z-index: 1000;
  border-radius: 16px 16px 0px 0px;
  background-color: var(--white);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  overflow-y: hidden;
  height: fit-content;
}

.DropdownList {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
}

.DropdownList_data {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 13px 20px;
}

.profile_DropdownList {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
}

.profile_DropdownList_data {
  display: flex;
  padding: 0px 16px;
  gap: 16px;
}

.profile_DropdownList_data_child {
  display: flex;
  padding: 0px 4px;
  gap: 4px;
  flex-direction: column;
  cursor: pointer;
}

.profile_DropdownList_content {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: var(--smoky-black);
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  color: var(--smoky-black);
}
.sort_by_text{
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  color: var(--smoky-black);
}

.thisYear_radiobtn {
  width: 20px;
  height: 20px;
}

.logout_container {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  gap: 24px;
}

.logout_popup {
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  gap: 6px;
}

.logout_heading {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.4px;
  color: var(--smoky-black);
}

.logout_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--light-black);
}

.billing_container {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
}

.billing_logout_text {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  color: var(--smoky-black);
}

.footer_logout_btn {
  display: flex;
  gap: 16px;
}

@media (max-width: 768px) {
  .right_section {
    margin-left: 0;
  }

  .mobileNavbar {
    display: flex;
  }
}
