.dashboard_section {
  margin-top: clamp(9rem, 22vh, 10rem);
  height: 100%;
}

/* .dashboard_section div {
  height: 100vh;
} */

@media (max-width: 768px) {
  .dashboard_section {
    margin-top: 14vh;
  }
}

@media (max-width: 550px) {
  .dashboard_section {
    margin-top: 11vh;
  }
}
