.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  padding: 24px 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  box-shadow: 0px -4px 10px 0px #0000000d;
  z-index: 999;
}

.sortBy_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.filter_body {
  overflow-y: scroll;
  height: 28rem;
  margin-top: 73px;
  margin-bottom: 115px;
}

.calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pickerStyle {
  width: 90vw;

  max-width: 400px;
}

.datePickOption {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-grey);
  border: 0.5px solid var(--grey-80);
  padding: 10px 10px;
  width: 60%;
  border-radius: 8px;
  font-size: medium;
}

.calendar_container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap:16px;
}

.calendar_container h1 {
  color: var(--light-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 102px;
  padding: 24px 16px;
  position: fixed;
  gap: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  box-shadow: 0px -4px 10px 0px #0000000d;
  z-index: 999;
}

.sub_footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
}

.footer p{
  color: var(--red);
}
