.invoice_page_parent {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 14px;
  border-radius: 8px;
  background-color: var(--white);
}

.invoice_page {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 8px;
  padding: 12px 24px 12px 24px;
  gap: 24px;
  width: 60vw;
  max-height: 90vh;
  overflow-y: scroll;
  font-size: 14px;
  word-wrap: break-word;
}

.invoice_page::-webkit-scrollbar {
  width: 5px;
}
.invoice_page::-webkit-scrollbar-track {
  background-color: var(--light-grey);
  border-radius: 8px;
  position: absolute;
  right: 10px;
}

.invoice_page::-webkit-scrollbar-thumb {
  background-color: var(--grey-80);
  border-radius: 8px;
}

/* .invoice_page p {
  color: var(--grey);
} */

.invoice_page .header {
  display: flex;
  justify-content: space-between;
}

.header_middle_section {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 7px;
}

.section_1 {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  word-break: break-all;
}

.section_1_sub_section_1 {
  text-align: left;
}

.section_1_sub_section_1 p:not(:first-child) {
  color: var(--grey);
}

.section_1_sub_section_2 {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.section_1_sub_section_2 p:not(:first-child) {
  color: var(--grey);
}

.line {
  margin: 0rem;
  margin-bottom: -1rem;
}

.innerLine {
  display: none;
}

.outer_line {
  display: block;
  margin: 0rem;
}

.heading {
  color: var(--primary);
  font-weight: 700;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  word-wrap: break-word;
  word-break: break-all;
}

.row_1 {
  font-weight: 700;
  background-color: var(--light-beige);
}

.row_2,
.row_3 {
  font-weight: 500;
}

.row_4 {
  font-weight: 500;
  background-color: var(--yellow);
  font-size: 18px;
  font-weight: 700;
}

.invoice_page .section3 {
  text-align: left;
}

.section_3 p:not(:first-child) {
  color: var(--grey);
}

.footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.footer p {
  text-align: center;
  width: 100%;
}

.footer p:not(:first-child) {
  color: var(--grey);
}

.footer_line_1 {
  color: var(--primary);
  font-family: "combo";
}

.clickable {
  display: block;
  cursor: pointer;
}

@media (max-width: 786px) {
  .section_1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    /* gap: 24px; */
  }

  .section_1_sub_section_2 {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .innerLine {
    display: block;
  }

  .outer_line {
    display: none;
  }

  .invoice_page {
    width: 90vw;
  }
}

@media (max-width: 550px) {
  .invoice_page_parent {
    padding-top: 5px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-radius: 8px;
    background-color: var(--white);
  }

  .invoice_page {
    font-size: 12px;
    padding: 12px 12px 12px 12px;
  }
  .row {
    padding: 8px 5px;
  }
  .row_4 {
    font-size: 14px;
  }
}
