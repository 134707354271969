.active_page {
  height: 100vh;
}

.label {
  color: var(--grey);
}

.section_heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 28.64px;
  text-align: left;
  color: rgba(0, 24, 61, 1);
  margin: 2rem 0rem 2rem 2rem;
}

.service_table {
  padding-left: 2rem;
  width: 100%;
  margin-bottom: 4rem;
  position: relative;
}

.service_table thead tr {
  background-color: rgba(230, 237, 245, 1);
  border: 1px solid rgba(22, 93, 201, 0.4);
  height: 40px;
  font-weight: 500;
  font-size: 16px;
}

.service_table tbody tr {
  /* height: 79px; */
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
  border-bottom: 1px solid var(--grey-80);
}

.service_table tbody tr td {
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 12px; */
  /* width: fit-content; */
  /* margin: 10px 15px; */
  padding: 15px 5px;
}

.service_table tbody td {
  vertical-align: top;
}

.service_table tbody tr td p {
  margin-bottom: 10px;
}

.service_table thead tr th:nth-child(1),
.service_table tbody tr td:nth-child(1) {
  padding-left: 2rem;
}

.action_section {
  display: flex;
  align-items: center;
  gap: 16px;
  /* height: 79px; */
}

.action_section .notificationToggle {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  background: rgba(26, 115, 232, 0.04);
}

.action_section .action_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 40px;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.action_section .edit_button {
  background: rgba(254, 212, 98, 0.2);
}

.action_section .delete_button {
  background: rgba(228, 50, 50, 0.1);
}

.empty_state_div {
  display: none;
}

/* ----------------------------------------Service card css ------------------------------------------*/

.service_card {
  display: none;
  flex-direction: column;
  padding: 30px 24px 30px 24px;
  gap: 16px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 2px 0px rgba(29, 22, 23, 0.15);
  margin-bottom: 10px;
}

.service_card_top_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-80);
}

.service_name {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  color: var(--black);
}

.sector_name {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: var(--dark-grey);
}

.service_card_middle_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.text_label {
  color: var(--dark-grey);
}

.service_card_bottom_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.active {
  color: var(--mint-green);
  display: flex;
  align-items: center;
  gap: 16px;
}

@media (max-width: 545px) {
  .active_page {
    background-color: var(--light-grey);
    margin-bottom: 4.5rem;
    margin-top: -1rem;
    height: 90vh;
  }
  .section_heading {
    display: none;
  }

  .service_card {
    display: flex;
  }

  .service_table {
    display: none;
  }

  .empty_state_div {
    display: flex;
  }
}
