.component_container {
  background-color: var(--white);
  position: relative;
  overflow: hidden;
}

.textarea {
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  height: 15rem;
  padding: 12px 20px;
  color: var(--smoky-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  border-radius: 8px;
  background: var(--light-grey);
}

.amount_section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}

.bottom_button_section {
  display: flex;
  justify-content: space-between;
  /* margin: 1rem 2rem; */
}

.bottom_button_section_header {
  background-color: var(--blue);
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px 20px;
}

.body_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.main_container_form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr; */
  gap: 24px;
}

@media (max-width: 786px) {
  .main_container_form {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
