tr td.status_td {
  border-bottom: none;
  padding-left: 2.5%;
  padding-top: 1%;
  padding-bottom: 0px;
}

.status_bar {
  display: flex;
  align-items: center;
  gap: 10px;
  display: flex;
  padding: 4px 10px 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border-radius: 18px;
  width: fit-content;
}

tr.Progress_bar_data {
  border-radius: 0px;
  box-shadow: 0px 0px 0px 0px white;
}

.work_progress {
  color: var(--royal-blue);
  background-color: rgba(26, 115, 232, 0.2);
}

.verification_pending {
  margin-top: 10px;
  background: var(--white-linen);
  color: var(--dark-goldenrod);
}

.messageIcon {
  /* min-width: 3rem !important; */
  margin: 0rem 0.5rem;
  text-align: center;
}
