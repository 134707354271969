.container {
  display: flex;
  padding: 20px;
  gap: 20px;
  height: 100%;
  margin-bottom: 4rem;
}

.left_container {
  width: 40%;
  /* margin-right: 20px; */
  min-width: 15rem;
}

.left_box {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  background: var(--white);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(29, 22, 23, 0.05);
  min-width: 15rem;
  border: 0.5px solid rgba(137, 176, 235, 0.5);
}

.left_box .left_img {
  float: left;
  margin-right: 1rem;
}

.left_box h1 {
  text-align: left;
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 33.41px;
}

.left_box p {
  text-align: left;
  margin: 5px 0 0;
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 1rem;
  line-height: 22.4px;
}

.change {
  display: flex;
  justify-self: center;
  /* width: 4rem; */
  width: auto;
  font-size: 12px;
  margin-top: -20px;
  color: var(--white);
  padding: 5px 10px;
  border-radius: 5px;
}

.negative {
  background-color: var(--light-red);
  color: var(--pantone-red);
}

.positive {
  background-color: var(--light-green);
  color: var(--wild-green);
}

.right_container {
  width: 70%;
  background: var(--white);
  margin-bottom: 10px;
  /* padding: 20px; */
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 0.5px solid rgba(137, 176, 235, 0.5);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
  padding: 20px;
  background-color: var(--light-blue);
}

.header h2 {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 22.4px;
}

.header a {
  text-decoration: none;
  color: var(--royal-blue);
  font-weight: 500;
  font-size: 16px;
  line-height: 19.09px;
}

.right_box {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  align-items: flex-start;
  padding: 20px 20px 5px;
  border-top: 0.5px solid rgba(137, 176, 235, 0.5);
}

.right_data p {
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  line-height: 22.4px;
  margin-bottom: 1rem;
}

.right_data .order_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--grey);
}

.right_data span {
  color: var(--royal-blue);
}

/* .right_data button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
} */

/* .accept {
    background-color: var(--green);
    border: 1px solid var(--green);
    color: var(--white);
}

.reject {
    background-color: var(--white);
    border: 1px solid var(--red);
    color: var(--red);
}

.reject:hover {
    background-color: var(--red);
    color: var(--white);
} */

.actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.footer_btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  margin-top: 10px;
}

.right_bottom_data {
  color: var(--grey);
  font-weight: 400;
  font-size: 12px;
  line-height: 19.2px;
  /* margin-bottom: -90px; */
}

.headerOnMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  background-color: var(--white);
}

.headerOnMobile h2 {
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.09px;
  color: var(--smoky-black);
}

.headerOnMobile a {
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  color: var(--royal-blue);
}

.headerOnMobile {
  display: none;
}

.hideOnDesktop {
  display: none;
}

.emptybox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vw;
  font-size: 2rem;
  text-align: center;
}

@media (max-width: 992px) {
  .left_container {
    min-width: 20rem;
  }
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    padding: 0px;
  }

  .left_container {
    display: flex;
    gap: 20px;
    width: 100%;
    padding: 16px;
    overflow-x: scroll;
    margin-bottom: 0rem;
  }

  .left_box {
    border: 0.5px solid var(--grey-80);
    min-width: 22rem;
    margin-bottom: 0px;
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1);
  }

  .header {
    display: none;
  }

  .headerOnMobile {
    display: flex;
    width: 90%;
    padding: 20px 0px;
  }

  .right_container {
    width: 90%;
    /* min-width: 19rem; */
    border: 0.5px solid var(--grey-80);
  }

  .right_box {
    padding: 24px 16px 10px;
    border-top: 0px;
    border-bottom: 0.5px solid var(--grey-80);
  }

  .right_box:last-child {
    border-bottom: 0px;
  }
}

@media (max-width: 550px) {
  .emptybox {
    font-size: 1.3rem;
  }

  .hideOnDesktop {
    display: flex;
  }

  .hideOnMobile {
    display: none;
  }

  /* .right_bottom_data {
        margin-bottom: -75px;
    } */
}

@media (max-width: 400px) {
  .change {
    padding: 4px 8px;
  }

  .left_box .left_img {
    width: 36px;
    height: auto;
  }

  .left_box {
    min-width: 17rem;
    padding: 12px 14px;
  }

  .left_box h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
  }

  .left_box p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
  }

  .right_data p {
    font-size: 16px;
  }

  .right_bottom_data {
    text-align: right;
    font-size: 10px;
  }

  .right_data .order_text {
    font-size: 14px;
  }
}
