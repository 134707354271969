.RaiseTicket_container {
    width: auto;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 45px;
    margin-top: 14vh;
    height: 100%;
}

.RaiseTicket_first_data {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 70%;
}

.RaiseTicket_Heading {
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--smoky-black);
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
}

.create_ticket {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-blue);
    border: 1px solid var(--grey-80);
    gap: 10px;
    border-radius: 8px;
    padding: 12px 16px;
    min-width: 16rem;
    height: 120px;
    text-align: center;
    cursor: pointer;
}

.leftArrowIcon {
    cursor: pointer;
}


@media (max-width: 768px) {
    .RaiseTicket_container {
        padding: 20px 16px;
        margin-top: 0vh;
        gap: 24px;
        margin-bottom: 4rem;
    }

    .RaiseTicket_first_data {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }
}

@media (max-width: 550px) {
    .RaiseTicket_container {
        margin-bottom: 0rem;
    }

    .RaiseTicket_Heading {
        font-size: 16px;
        font-weight: 600;
        line-height: 22.4px;
    }

    .filter_ticket_container {
        display: flex;
        justify-content: space-between;
    }

    .filter_ticket {
        display: flex;
        gap: 6px;
        padding: 6px 10px;
        border-radius: 6px;
        border: 1px solid rgba(204, 204, 204, 0.5);
        box-shadow: 0px 2px 20px 0px #1D16170D;
    }

    .filter_ticket_data {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        color: var(--smoky-black);
    }

}