.attachmentPageParent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.5);
}

.attachmentPageMobile {
  display: none;
}

.attachmentPage {
  position: relative;
  width: 730px;
  height: 90vh;
  background-color: var(--white);
  border-radius: 24px;
  padding: 20px 24px;
}

.heading {
  margin-left: 10%;
}

.closeIcon {
  position: absolute;
  top: 25px;
  right: 20px;
  cursor: pointer;
}

.rightArrow,
.leftArrow {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.leftArrow {
  transform: scale(1.2);
}

.upperBlock {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 65%;
}

.upperBlockImage {
  border-radius: 12px;
  height: 80%;
  width: 80%;
  transition: all 10s ease-out;
}

.lowerBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  height: 35%;
  padding: 10px 0rem;
  max-height: 8rem;
  overflow-y: scroll;
}

.lowerBlock::-webkit-scrollbar {
  width: 3px;
}

.lowerBlock::-webkit-scrollbar-track {
  background-color: var(--light-grey);
  border-radius: 8px;
}

.lowerBlock::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 8px;
}

.array_image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  height: 50%;
  width: 20%;
}

.selected_array_image {
  transform: scale(1.2);
  border: 3px solid black;
}

.full_image {
  width: 90vw;
  height: 100%;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .attachmentPageParent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 9999999;
    background-color: var(--white);
  }

  .attachmentPageMobile {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100vh;
    padding: 5rem 0rem;
  }

  .attachmentPage {
    display: none;
  }

  .heading {
    display: none;
  }
}
