.input_parent {
  position: relative;
  height: 46px;
  max-width: 332px;
  background-color: var(--white);
  border-radius: 8px;
  padding: 0px 18px;
  border: 0.5px solid var(--grey-80);
}

.input {
  height: 100%;
  width: 80%;
  outline: none;
  font-weight: 400;
  font-size: 0.9rem;
  border: none;
  padding-left: 10px;
  background: transparent;
  line-height: 19.09px;
}

.input::placeholder {
  text-align: left;
  color: var(--grey);
  font-size: 0.9rem;
}

/* @media(max-width:1024px) {
  .input_parent {
    margin-left: 7.3rem;
    margin-top: 0.3rem;
  }
} */

@media (max-width: 768px) {
  .input_parent {
    position: relative;
    height: 40px;
    width: 100%;
    background-color: var(--white);
    border-radius: 8px;
    padding: 0px 18px;
    border: 0.5px solid var(--grey-80);
    margin: 0px;
  }
}

@media (max-width: 375px) {
  .input_parent {
    width: 90%;
  }
}