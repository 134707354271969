.notification {
  width: auto;
  padding: 24px 30px 24px 40px;
  display: flex;
  gap: 8px;
  border-bottom: 0.5px solid var(--grey-80);
  position: relative;
}

.notification_open_threedot_menu {
  width: auto;
  padding: 24px 30px 24px 40px;
  display: flex;
  gap: 8px;
  border-bottom: 0.5px solid var(--grey-80);
  position: relative;
}

.notification_small {
  width: auto;
  padding: 12px 20px;
  display: flex;
  gap: 8px;
  border-bottom: 0.5px solid var(--grey-80);
  z-index: 999;
}

.notification_data {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.notification_data_child {
  display: flex;
  gap: 6px;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  color: var(--smoky-black);
}

.notification_data_child_small {
  display: flex;
  gap: 6px;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  line-height: 22.4px;
  color: var(--smoky-black);
}

.status_dot {
  display: flex;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  margin-top: 3px;
  margin-right: 12px;
}

.status_dot_small {
  display: flex;
  min-width: 7px;
  min-height: 7px;
  border-radius: 50%;
  margin-top: 7px;
  margin-right: 12px;
}

.threedot {
  /* display: flex;
    align-items: center; */
  display: none;
  cursor: pointer;
}

.notification:hover .threedot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.show_threedot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--light-grey);
}

.time {
  color: var(--grey);
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
}

.time_small {
  color: var(--grey);
  font-size: 10px;
  font-weight: 400;
  line-height: 19.2px;
}

.colon_popup {
  display: none;
  width: 10%;
  min-width: fit-content;
  height: auto;
  border-radius: 8px;
  padding: 8px 0px;
  background-color: var(--white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  position: absolute;
  right: 70px;
  top: 60px;
}

.colon_item {
  display: flex;
  padding: 7px 12px;
  gap: 6px;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.09px;
  color: var(--smoky-black);
  cursor: pointer;
}

.colon_item:hover {
  background-color: var(--light-grey);
}

.footer a{
  width: auto;
  padding: 12px 20px 12px 40px;
  display: flex;
  gap: 8px;
  background-color: var(--white);
  color: var(--royal-blue);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .notification {
    padding: 16px;
  }

  .notification_data_child {
    font-size: 14px;
    line-height: 19.6px;
  }

  .notification:hover .threedot {
    display: none;
  }
}
