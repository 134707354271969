/* body{
    font-family: 'SF Pro Display';
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
} */

.modelBox {
    position: relative;
    width: 100%;
    padding: 35px;
    padding-bottom: 0px;
}

.modelBox_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blue);
    padding: 2.5% 3%;
    height: max(10%, 3rem);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.modelBox_header span {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 22.4px;
    color: var(--white);
}

.modelBox_body {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 0.5px solid var(--grey-80);
    width: 100%;
    height: fit-content;
    min-width: 20rem;
}

.details {
    text-align: left;
    display: flex;
    gap: 20px;
    padding: 3.7%;
    /* padding: 18px 2px; */
    border-bottom: 0.5px solid var(--grey-80);
}

.details:last-child {
    border-bottom: none;
}

.details span {
    display: flex;
    /* width: 100%; */
    /* text-align: left; */
    padding: 0px 16px 0px 16px;
}

.details .label {
    width: 100%;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19.6px;
    color: var(--grey);
}

.details .value {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 6rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19.6px;
    color: var(--smoky-black);
    gap: 8px;
    word-break: break-word;
}

.modelBox_footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.radioData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    cursor: pointer;
}

/* .modelBox_footer_mobileview{
    display: none;
} */

@media(max-width:992px) {
    .modelBox {
        padding: 30px 20px 20px 20px;
    }

    /* .modelBox_body{
        width: 20rem;
    } */
    .modelBox_header {
        padding: 3%;
    }

    .details {
        gap: 4px;
        /* padding: 20px 12px; */
    }

    .details span {
        padding: 0px 10px;
    }

    .details .label {
        width: 50%;
    }

    .details .value {
        width: 50%;
        gap: 6px;
        min-width: 8rem;
    }

    .radioData {
        width: 50%;
        gap: 10px;
    }
}

@media(max-width:550px) {
    .modelBox_body {
        width: 0rem;
    }

    .modelBox_header span {
        font-size: 16px;
    }

    .modelBox_footer {
        display: none;
    }
    .details {
        padding: 15px 10px;
    }

    .details .value {
        font-size: 14px;
        width: 71%;
    }

    .details .label {
        font-size: 14px;
        width: 60%;
    }

    .radioData {
        width: 65%;
    }

}

@media(max-width:400px) {
    .modelBox {
        padding: 30px 10px 20px 10px;
    }

    .details {
        padding: 15px 4px;
    }
    /* .details {
        padding: 20px 4px;
    } */
}
@media(max-width:370px){
    .modelBox_body {
       min-width: 16rem;
     }
   }



/* @media(max-width:992px){
    .modelBox{
        padding: 30px 20px 20px 20px;
    }
    .modelBox_body{
        margin: 55px 0px 30px 0px;
        width: 20rem;
    }
    .details {
        gap: 4px;
        padding: 20px 12px;
    }
    .details span{
        padding: 0px 10px;
    }
    .details .label{
        width: 50%;
    }
    .details .value{
        width: 50%;
        gap: 6px;
        min-width: 8rem;
    }
    .radioData {
        width: 55%;
        gap: 10px;
    }
}
 */