.modalOverlay {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.modalContent {
  /* background: var(--white); */
  border-radius: 24px;
  position: relative;
  /* width: auto; */
  /* height: 90%; */
  /* padding: 10px; */
  /* overflow-y: scroll; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 90vh; */
  /* width: 100%; */
  width: 90%;
  max-width: 1050px;
}

.modalClose {
  position: absolute;
  right: 10px;
  font-size: 50px;
  cursor: pointer;
  padding: 10px;
}

@media (max-width: 768px) {
  .modalContent {
    width: 70vw;
  }
}

@media (max-width: 550px) {
  .modalContent {
    width: 90vw;
  }
}
