.profileInput {
  border-radius: 8px;
  padding: 12px 20px;
  background-color: var(--light-grey);
  border: none;
  outline: none;
  width: 100%;
  color: var(--smoky-black);
  text-align: left;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: 1px solid var(--white);
}

.profileInput:focus {
  border: 1px solid var(--grey-80);
}

.profileInput::placeholder {
  color: var(--grey);
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  display: flex;
  align-items: center;
}

.emptyInput {
  position: relative;
  /* pointer-events: none; */
}

/* .emptyInput::before {
  content: "";
  border-radius: 8px;
  border: 1px solid red;
  padding: 23px 20px;
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top:0;
  bottom:0;
} */

.emptyInput::after {
  content: "*required";
  color: var(--red);
  position: absolute;
  font-size: 14px;
  left: 7px;
  bottom: -22px;
}

@media (max-width: 550px) {
  .profileInput{
    font-size: 14px;
  }
  .profileInput::placeholder {
    font-size: 14px;
    line-height: 16.71px;
  }
}
