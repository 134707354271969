.rating_card_container {
    width: 100%;
    max-width: 354px;
    border-radius: 8px;
    padding: 12px 20px;
    display: flex;
    gap: 10px;
    box-shadow: 0px 10px 40px 0px #1D16170F;
}

.rating_card_detail {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

.rating_heading {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
    color: var(--smoky-black);
}

.rating_value {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    color: var(--smoky-black);
}

.line {
    border-bottom: 1px solid var(--grey-80);
}

.profile_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.profile_section {
    display: flex;
    justify-content: space-between;
}

.profile_section_value {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    color: var(--smoky-black);
}

.date {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    color: var(--grey);
}

.bottom_data {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.bottom_data_heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    color: var(--smoky-black);
}

.bottom_data_value {
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    color: var(--smoky-black);
}