.add_service_button_component {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  position: fixed;
  top: clamp(5.5rem, 7vh, 7rem);
  right: 30px;
  z-index: 10;
}

.add_service_link {
  display: none;
  justify-content: space-between;
  gap: 30px;
  position: fixed;
  top: clamp(5.5rem, 8vh, 7rem);
  right: 20px;
  z-index: 10;
}

.add_service_link .link {
  color: var(--blue);
  display: flex;
  gap: 6px;
}

.dashboard_section {
  margin-top: clamp(10rem, 22vh, 10rem);
}

@media (max-width: 545px) {
  .add_service_button_component {
    display: none;
  }
  .add_service_link {
    display: flex;
  }
}
