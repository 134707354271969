/* SalesProgressBar */

.salesProgressBar {
  width: 80%;
  max-width: 25rem;
  max-height: 20rem;
  position: absolute;
  bottom: -7vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.salesProgressBarText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  text-align: center;
  width: 100%;
  font-size: 22px;
  color: var(--smoky-black);
}

.salesProgressBarAmount {
  color: var(--smoky-black);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.salesProgressBarSubText {
  color: var(--grey);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
}

.zero {
  color: var(--grey);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px; /* 140% */
  position: absolute;
  left: -25px;
}

.hundred {
  color: var(--grey);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px; /* 140% */
  position: absolute;
  right: -40px;
}

@media (max-width: 980px) {
  .salesProgressBar {
    position: relative;
    top:90px;
  }
}


@media (max-width: 550px){

  .salesProgressBar {
    width: 70%;
    max-width: 25rem;
    /* height: 20rem; */
    position: absolute;
    bottom: -7vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
  
  @media (max-width: 450px){

  .salesProgressBarAmount {
    color: var(--smoky-black);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .salesProgressBarSubText {
    color: var(--grey);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
  }

  .zero ,.hundred {
    font-size: 12px;
  }
}