.navButton_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 0.5px solid var(--grey-80);
  background: var(--floral-white);
  display: flex;
  width: 100%;
  padding: 10px 40px 20px 40px;
  position: fixed;
  top:  clamp(4.5rem, 5vh, 8rem);
  right: 0;
  left: 5%;
  z-index: 9;
  /* margin-top: -12vh; */
}

.navButton {
  border-top: 1px solid var(--grey-80);
  border-bottom: 1px solid var(--grey-80);
  display: flex;
  padding: 0.5% 2.5%;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--white);
}

.navButton:hover {
  background-color: rgba(250, 224, 152, 0.1);
  cursor: pointer;
}

.navButton1 {
  border-radius: 8px 0px 0px 8px;
  border: 1px solid var(--grey-80);
  border-right: none;
  min-width: 6rem;
}

.navButton2 {
  border-top: 1px solid var(--grey-80);
  border-bottom: 1px solid var(--grey-80);
  border-left: 1px solid var(--grey-80);
}

.navButton3 {
  border-radius: 0px 8px 8px 0px;
  border: 1px solid var(--grey-80);
}
/* 
@media (max-width: 768px) {
  .navButton_container {
    overflow-x: scroll;
    padding: 20px 10px;
    top: 0;
    right: 0;
    left: 0;
  }
} */

@media (max-width: 768px) {
  .navButton_container {
    overflow-x: scroll;
    padding: 16px;
    top: 65px;
    right: 0;
    left: 0;
    border-bottom: 0.5px solid var(--grey-80);
  }

  .navButton {
    font-size: 14px;
    /* width: 100px; */
    height: 36px;
    padding: 15px 20px;
    gap: 10px;
  }

  .navButton1 {
    border-radius: 8px 0px 0px 8px;
    border: 1px solid var(--grey-80);
    border-right: none;
    min-width: 8rem;
  }
}

@media(max-width:450px){
  .navButton {
    min-width: 10.2rem;
    padding: 0px 20px;
  }
}